<template>
  <div id="event-details-report">
    <h1>{{ $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.TITLE") }}</h1>
    <v-app>
      <form class="mt-5">
        <div class="row">
          <div class="col-6">
            <label for="event">{{
              $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.EVENT")
            }}</label>
            <v-autocomplete
              filled
              solo
              @change="selectEvent"
              v-model="selectedEvent"
              :items="events"
              item-text="eventName"
              item-value="id"
              :placeholder="
                $t('REPORTS.EVENT_DETAILS_REPORT_PAGE.EVENT_PLACEHOLDER')
              "
            ></v-autocomplete>
          </div>
          <div class="col-6">
            <label for="event">{{
              $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.DOCTOR")
            }}</label>
            <v-autocomplete
              filled
              solo
              v-model="selectedDoctor"
              :items="assignedDoctors"
              :item-text="formatDoctorLabel"
              item-value="doctorId"
              return-object
              :placeholder="
                $t('REPORTS.EVENT_DETAILS_REPORT_PAGE.DOCTOR_PLACEHOLDER')
              "
            ></v-autocomplete>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            :disabled="!selectedEvent || !selectedDoctor"
            @click="onSubmit"
            class="btn-primary"
            >{{ $t("COMMON.SUBMIT") }}</b-button
          >
        </div>
      </form>
    </v-app>

    <template v-if="showTable">
      <!-- <template v-if="selectedDoctor.doctorId === 'all'">
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <label for="event">{{
                  $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.SPECIALITY")
                }}</label>
                <v-autocomplete
                  filled
                  solo
                  @change="selectSpeciality"
                  v-model="selectedSpeciality"
                  :items="specialities"
                  item-text="TextEn"
                  item-value="value"
                  :placeholder="
                    $t(
                      'REPORTS.EVENT_DETAILS_REPORT_PAGE.SPECIALITY_PLACEHOLDER'
                    )
                  "
                ></v-autocomplete>
              </div>
              <div class="col-6">
                <label for="event">{{
                  $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.GOVERNORATE")
                }}</label>
                <v-autocomplete
                  filled
                  solo
                  @change="selectGovernorate"
                  v-model="selectedGovernorate"
                  :items="governorates"
                  item-text="governorate_name_en"
                  item-value="value"
                  :placeholder="
                    $t(
                      'REPORTS.EVENT_DETAILS_REPORT_PAGE.GOVERNORATE_PLACEHOLDER'
                    )
                  "
                ></v-autocomplete>
              </div>
            </div>
          </div>
        </div>
      </template> -->

      <div class="d-flex justify-content-end">
        <b-button @click="exportExcel('xls')" class="btn-success">{{
          $t("COMMON.EXTRACT")
        }}</b-button>
      </div>
      <h3 class="mt-2">{{ $t("EVENT.EVENT_DETAILS.ASSIGNED_DOCTORS") }}</h3>
      <b-table
        v-if="doctors.length > 0"
        striped
        hover
        :fields="$t('DOCTOR.FIELDS')"
        :items="doctors"
        ref="exportable_table"
        id="exportable_table"
      >
        <template #cell(name)="row">
          {{ formatDoctorLabel(row.item) }}
        </template>
        <template #cell(speciality)="row">
          {{ getSpeciality(row.item.speciality) }}
        </template>
        <template #cell(governorate)="row">
          {{ getGovernorate(row.item.governorate) }}
        </template>
        <template #cell(questionnaireSubmitted)="row">
          <b-button
            size="sm"
            v-if="!row.item.questionnaireSubmitted"
            disabled
            class="mr-2 btn-primary"
          >
            {{ $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.NOT_SUBMITTED") }}
          </b-button>
          <b-button
            size="sm"
            v-else
            @click="
              $router.push({
                name: 'Questionnaire Details',
                params: {
                  eventId: selectedEvent,
                  doctorId: row.item.doctorId,
                },
              })
            "
            class="mr-2 btn-primary"
          >
            {{ $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.GO_TO_QUESTIONNAIRE") }}
          </b-button>
        </template>
      </b-table>
      <template v-else>
        <b-alert class="mt-5" show
          >Cannot find anything with the current filters</b-alert
        >
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { utils, write, writeFile } from "xlsx";
import constants from "@/common/config/constants.json";

export default {
  created() {
    this.getEvents();
  },
  watch: {
    selectedSpeciality() {
      if (this.selectedGovernorate) {
        this.doctors = this.assignedDoctors.filter(
          (doc) =>
            doc.speciality === this.selectedSpeciality &&
            doc.governorate === this.selectedGovernorate
        );
      }
    },
    selectedGovernorate() {
      if (this.selectedSpeciality) {
        this.doctors = this.assignedDoctors.filter(
          (doc) =>
            doc.speciality === this.selectedSpeciality &&
            doc.governorate === this.selectedGovernorate
        );
      }
    },
  },
  data() {
    return {
      selectedEvent: null,
      selectedDoctor: null,
      selectedSpeciality: null,
      selectedGovernorate: null,
      showTable: false,
      doctors: [],
      filteredDoctors: [],
    };
  },
  methods: {
    ...mapMutations({
      SET_ASSIGNED_DOCTORS: "SET_ASSIGNED_DOCTORS",
    }),
    ...mapActions({
      getEvents: "getEvents",
      getAssignedDoctors: "getAssignedDoctors",
    }),
    formatDoctorLabel(doctor) {
      // Check if the doctor name is "Dummy Doctor"
      if (doctor.name === "Dummy Doctor") {
        return `${doctor.name} (ID: ${doctor.doctorId})`;
      }
      // For all other doctors, return only the name
      return doctor.name;
    },
    selectEvent() {
      this.getAssignedDoctors({
        eventId: this.selectedEvent,
        report: true,
      });
    },
    selectSpeciality() {
      this.doctors = this.assignedDoctors.filter(
        (doc) => doc.speciality === this.selectedSpeciality
      );
    },
    selectGovernorate() {
      this.doctors = this.assignedDoctors.filter(
        (doc) => doc.governorate === this.selectedGovernorate
      );
    },
    onSubmit() {
      this.showTable = true;
      if (this.selectedDoctor.doctorId === "all") {
        this.doctors = JSON.parse(JSON.stringify(this.assignedDoctors));
        this.doctors.shift();
      } else {
        this.doctors = [this.selectedDoctor];
      }
    },
    getSpeciality(id) {
      const language = localStorage.getItem("language");
      const speciality = this.specialities.find((spec) => spec.value == id);
      if (speciality && speciality.value !== null) {
        // Check for valid speciality and not a placeholder
        if (
          speciality.TextEn === "Please select an option" ||
          speciality.TextAr === "اختار التخصص"
        ) {
          return "----";
        }
        return language === "ar" ? speciality.TextAr : speciality.TextEn;
      }
      return "----";
    },
    getGovernorate(id) {
      const language = localStorage.getItem("language");
      const governorate = this.governorates.find((gov) => gov.value == id);
      if (governorate && governorate.value !== null) {
        // Check for valid governorate and not a placeholder
        if (
          governorate.governorate_name_en === "Please select an option" ||
          governorate.governorate_name_ar === "اختار المحافظة"
        ) {
          return "----";
        }
        return language === "ar"
          ? governorate.governorate_name_ar
          : governorate.governorate_name_en;
      }
      return "----";
    },
    exportExcel(type, fn, dl) {
      let table = document.getElementById("exportable_table");
      const clone = table.cloneNode(true);
      var row = clone.rows;

      for (var i = 0; i < row[0].cells.length; i++) {
        let str = row[0].cells[i].innerHTML;

        if (
          str.search("Questionnare Status") != -1 ||
          str.search("حالة الاستبيان") != -1
        ) {
          for (var j = 0; j < row.length; j++) {
            // Deleting the ith cell of each row
            row[j].deleteCell(i);
          }
        }
      }

      let wb = utils.book_new();
      let ws = utils.table_to_sheet(clone);

      ws["!cols"] = [];

      // let wb = utils.table_to_book(clone, { sheet: "Sheet JS" });
      ws["!cols"] = [
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
      ]; // hidding col C

      utils.book_append_sheet(wb, ws, "sheet1");

      return dl
        ? write(wb, { bookType: type, bookSST: true, type: "base64" })
        : writeFile(wb, fn || "SheetJSTableExport." + (type || "xlsx"));
    },
  },
  computed: {
    ...mapGetters({
      events: "events",
      assignedDoctors: "assignedDoctors",
    }),
    specialities: () => {
      return constants.specialities;
    },
    governorates() {
      return constants.governorates;
    },
  },
};
</script>

<style lang="scss" scoped>
#event-details-report {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }

  form {
    border: 1px solid #494b53;
    padding: 15px;
    label {
      font-weight: 400;
      font-size: 16px;
      color: #3c4eba;
    }

    button {
      width: 150px;
    }
  }

  h3 {
    font-size: 16px;
  }
}
::v-deep .v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 40px !important;
}

::v-deep .v-application--wrap {
  min-height: 50vh;
}
</style>
